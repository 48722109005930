import styles from "./Home.module.css";
import pivotea from "../../assets/newsletter.png";
import Card from "../../components/Card/Card";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; 
import Button from "../../components/Button/Button";
import { useEffect, useState } from "react";

export default function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      const authCookie = Cookies.get("authUser");
      if (authCookie) {
        console.log("Login found in cookies");
        const user = JSON.parse(authCookie);
        setAuthUser(user);
        setIsLoggedIn(true);
      } else {
        console.log("No auth cookie found, redirecting to login");
        navigate("/login");
      }
    }
  }, [isLoggedIn, setAuthUser, setIsLoggedIn, navigate]);

  const subscribe = () => {
    //console.log(email);
  };
  const sampleData = {
    cards: [
      {
        title: "Vocacionamiento",
        description:
          "Te ayudaremos a diseñar un propósito de vida que te permita definir tu siguiente paso profesional.",
        duration: "12 semanas",
        participants: "8 participantes",
        cover:
          "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "in progress",
        link: "/progreso",
        linkType: "internal"
      },
      {
        title: "Formación",
        description:
          "Accede a los recursos alineados a tus metas y habilidades",
        duration: "12 semanas",
        participants: "8 participantes",
        cover:
          "https://images.unsplash.com/photo-1615914143778-1a1a6e50c5dd?q=80&w=2068&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Explorar",
        link: "https://courses.skillingcenter.com/",
        linkType: "external"
      },
      {
        title: "Acompañamiento",
        description:
          "Ponte en contacto con tu acompañador, quien te guiará en el proceso.",
        duration: "12 semanas",
        participants: "8 participantes",
        cover:
          "https://images.unsplash.com/photo-1612832164313-ac0d7e07b5ce?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Ir a la comunidad",
        link: "https://chat.whatsapp.com/Ffdejw3Sx6iKboxL1NmlMp",
        linkType: "external"
      },
    ],
  };
  const cards = [];
  sampleData.cards.forEach((card) => {
    cards.push(
      <Card
        cover={card.cover}
        title={card.title}
        description={card.description}
        duration={card.duration}
        participants={card.participants}
        status={card.status}
        link={card.link}
        linkType={card.linkType}
      />
    );
  });
  return (
    <div className={styles.homeLayout}>
      <div className={styles.homeBanner}>
        <h1>TU ESPACIO DE TRABAJO</h1>
        <p>
          Encuentra todo lo necesario para tu experiencia dentro de PIVOTEA,
          desde que inicias hasta que terminas. No te preocupes, te acompañamos
          en cada paso.
        </p>
      </div>
      <div className={styles.homeCardContainer}>{cards}</div>
      <div className={styles.newsletterContainer}>
        <div className={styles.newsletterText}>
          <h4>BIENVENID@ A PIVOTEA</h4>
          <p>
            Si tienes alguna duda, comentario o simplemente quieres saludar,
            envía un correo a:{" "}
            <a href="mailto:pivotea@servicios.tecmilenio.mx" target="_blank" rel="noreferrer">
            pivotea@servicios.tecmilenio.mx
            </a>
          </p>
        </div>
        <div className={styles.newsletterCta}>
          <img src={pivotea} alt="Pivotea" />
          <div className={styles.newsletterForm}>
            <h4>FORMA PARTE DE FUTUROS PILOTOS</h4>
            <div>
              <p>Comparte tu email con nosotros</p>
              <input
                type="email"
                value={email}
                placeholder="usuario@email.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <Button
              type="outline"
              corners="rounded"
              text="Suscribirme"
              size="sm"
              onClick={subscribe}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
