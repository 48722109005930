import React, { useState, useEffect } from 'react';
import styles from './ConoFuturo.module.css';
import FlechaFebInverse from './Flechas/FlechaFebInversa'
import FlechaFeb from './Flechas/FlechaFeb'


const Nodo = ({ text, type, style }) => {
    return (
        <div className={`${styles.nodo} ${styles[type]}`} style={{
            ...style,
            fontSize: '12px',
            maxWidth: '80px',
            minWidth: '80px',
            minHeight: '30px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            alignContent: 'center'
        }}>
            {text || '\u00A0'}
        </div>
    );
};

const Flecha = ({ style, dotted = false, curved = false, length = 100 }) => {
    return (
        <svg
            className={styles.flecha}
            style={{ ...style, overflow: 'visible', width: `${length}px`, height: '29px' }}
            viewBox={`0 0 ${length} 50`}
            preserveAspectRatio="none"
        >


            {curved ? (
                <path
                    d={`M0,26 Q${length / 2},0 ${length - 10},110`}
                    stroke="white"
                    strokeWidth="10"
                    fill="none"
                    strokeDasharray={dotted ? '12,12' : 'none'}
                    strokeLinecap="inherit"
                    markerEnd="url(#arrowhead)"
                />
            ) : (
                <line
                    x1="0" y1="25"
                    x2={length - 10} y2="25"
                    stroke="white"
                    strokeWidth="10"
                    strokeDasharray={dotted ? '12,12' : 'none'}
                    strokeLinecap="inherit"
                    markerEnd="url(#arrowhead)"
                />
            )}
        </svg>
    );
};

const TextoEscenario = ({ text, style, color, rotation }) => {
    return (
        <div style={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "bold",
            color: color || "white",
            transform: `rotate(${rotation || 0}deg)`,
            whiteSpace: "nowrap",
            ...style,
        }}>
            {text}
        </div>
    );
};


const PuntaFlecha = ({ style }) => {
    return (
        <div
            style={{
                width: "0",
                height: "0",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: "15px solid white",
                position: "absolute",
                ...style,
            }}
        />
    );
};

const ConoFuturoImage = ({ data }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={styles.app}>
            <header className={styles.header}>
                <div className={styles.actions}>
                    <h1 className={styles.title}>Tus escenarios futuros</h1>
                    <FlechaFeb />
                    <FlechaFebInverse />
                    <FlechaFeb />
                </div>
            </header>
            <main className={styles.container}>
                <div id="conoFuturo" className={styles.cono}>
                    <div className={styles.overlay} style={{ overflow: 'hidden' }}>

                        <Nodo text={data.Puesto || ''} type="puesto" style={{ left: '7%', top: '46.8%' }} />
                        <Flecha
                            style={{ left: '3.9%', top: '23.5%', transform: 'rotate(70deg)' }}
                            length={400}
                            curved dotted
                        />
                        <PuntaFlecha style={{ top: "0.3%", left: "13.6%", transform: "rotate(29deg)" }} />
                        <TextoEscenario
                            text="Escenarios altamente divergentes"
                            color="#E592BA"
                            style={{
                                top: "28%",
                                left: "4%",
                                transform: `rotate(75deg)`
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios adyacentes"
                            color="#A4CBF4"
                            style={{
                                top: "29%",
                                left: "37%",
                                transform: `rotate(-50deg)`
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios ambiciosos"
                            color="#EFCE2A"
                            style={{
                                top: "12%",
                                left: "45.5%",
                                transform: `rotate(-50deg)`
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios directos"
                            color="#64CD84"
                            style={{
                                top: "53%",
                                left: "44%",
                            }}
                        />

                        <TextoEscenario
                            text="Escenarios ambiciosos"
                            color="#EFCE2A"
                            style={{
                                top: "53%",
                                left: "84%",
                            }}
                        />

                        <Flecha style={{ left: '10%', top: '47%' }} length={859} />
                        <PuntaFlecha style={{ top: "47.8%", left: "80.5%", transform: "rotate(270deg)" }} />



                        <Flecha style={{ left: '14.3%', top: '24%', transform: 'rotate(132deg)' }} length={518} />
                        <PuntaFlecha style={{ top: "0.1%", left: "49.9%", transform: "rotate(219deg)" }} />
                        <TextoEscenario
                            text="Escenarios adyacentes"
                            color="#A4CBF4"
                            style={{
                                top: "65%",
                                left: "19%",
                                transform: `rotate(45deg)`
                            }}
                        />
                        <TextoEscenario
                            text="Escenarios altamente divergentes"
                            color="#E592BA"
                            style={{
                                top: "83%",
                                left: "28%",
                                transform: `rotate(45deg)`
                            }}
                        />


                        <Flecha style={{ left: '15.8%', top: '70%', transform: 'rotate(42deg)' }} length={568} />
                        <PuntaFlecha style={{ top: "93.8%", left: "55.9%", transform: "rotate(185deg)" }} />

                        <Flecha style={{ left: '82%', top: '47%' }} length={218} />
                        <PuntaFlecha style={{ top: "47.8%", left: "99%", transform: "rotate(270deg)" }} />


                        {data.EscenariosAltamenteDivergentes?.[1]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[1] || ''} type="divergente" style={{ left: '17%', top: '34%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[2]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[2] || ''} type="divergente" style={{ left: '16%', top: '26%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[3]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[3] || '' || ''} type="divergente" style={{ left: '15%', top: '18%' }} />}
                        {data.EscenariosAltamenteDivergentes?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '13%', top: '10%' }} />}
                        {data.EscenariosAltamenteDivergentes?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '12%', top: '2.3%' }} />}

                        {data.EscenariosDirectos?.[1]?.trim() && <Nodo text={data.EscenariosDirectos?.[1] || ''} type="directo" style={{ left: '26.4%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[2]?.trim() && <Nodo text={data.EscenariosDirectos?.[2] || ''} type="directo" style={{ left: '36%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[3]?.trim() && <Nodo text={data.EscenariosDirectos?.[3] || ''} type="directo" style={{ left: '45.5%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[4]?.trim() && <Nodo text={data.EscenariosDirectos?.[4] || ''} type="directo" style={{ left: '54.5%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[5]?.trim() && <Nodo text={data.EscenariosDirectos?.[5] || ''} type="directo" style={{ left: '63.5%', top: '46%' }} />}
                        {data.EscenariosDirectos?.[6]?.trim() && <Nodo text={data.EscenariosDirectos?.[6] || ''} type="directo" style={{ left: '72.5%', top: '46%' }} />}

                        {data.EscenariosDirectos?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosDirectos?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '82%', top: '46%' }} />}
                        {data.EscenariosDirectos?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosDirectos?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '91%', top: '46%' }} />}

                        {data.EscenariosAdyacentes?.[1]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[1] || ''} type="adyacente" style={{ left: '26%', top: '34%' }} />}
                        {data.EscenariosAdyacentes?.[2]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[2] || ''} type="adyacente" style={{ left: '31%', top: '26%' }} />}
                        {data.EscenariosAdyacentes?.[3]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[3] || ''} type="adyacente" style={{ left: '35%', top: '18%' }} />}
                        {data.EscenariosAdyacentes?.["Ambicioso 1"]?.trim() && <Nodo text={data.EscenariosAdyacentes?.["Ambicioso 1"] || ''} type="ambicioso" style={{ left: '39%', top: '10%' }} />}
                        {data.EscenariosAdyacentes?.["Ambicioso 2"]?.trim() && <Nodo text={data.EscenariosAdyacentes?.["Ambicioso 2"] || ''} type="ambicioso" style={{ left: '43%', top: '2%' }} />}

                        {data.EscenariosAdyacentes?.[4]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[4] || ''} type="adyacente" style={{ left: '23%', top: '53%' }} />}
                        {data.EscenariosAdyacentes?.[5]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[5] || ''} type="adyacente" style={{ left: '28%', top: '60%' }} />}
                        {data.EscenariosAdyacentes?.[6]?.trim() && <Nodo text={data.EscenariosAdyacentes?.[6] || ''} type="adyacente" style={{ left: '33%', top: '67%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[4]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[4] || ''} type="divergente" style={{ left: '38%', top: '74%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[5]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[5] || ''} type="divergente" style={{ left: '43%', top: '81%' }} />}
                        {data.EscenariosAltamenteDivergentes?.[6]?.trim() && <Nodo text={data.EscenariosAltamenteDivergentes?.[6] || ''} type="divergente" style={{ left: '50%', top: '88%' }} />}

                    </div>
                </div>
            </main>
        </div>
    );
};

export default ConoFuturoImage;
