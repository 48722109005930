import React from 'react'
import "./Footer.css"
import tecmilenio from "../../assets/tecmilenio-logo.png";

function Footer() {
  return (
    <div className='footer'>
        <div className='logo-ctr'>
            <a href="https://tecmilenio.mx/">
                <img src={tecmilenio} alt="Tecmilenio" />
            </a>
        </div>
        <div className='contact'>
            <a href="mailto:pivotea@servicios.tecmilenio.mx" target='_blank'>CONTACTO</a>
            <p> - </p>
            <a href="https://tecmilenio.mx/es/aviso-de-privacidad/sc">POLÍTICA DE PRIVACIDAD</a>
            <p> - </p>
            <a href="/terminos">TERMINOS Y CONDICIONES</a>
        </div>
    </div>
  )
}

export default Footer