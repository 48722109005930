import React from "react";
import ConoFuturo from "./ConoFuturo";
import styles from "./ConodeFuturo.module.css";

const ConodeFuturo = ({ data, onSave }) => {
    return (
        <div className={styles.container}>
            <div style={{ position: "relative", marginTop: "20px" }}>
                <div className={styles.innerContainer}>
                    <ConoFuturo data={data} onSave={onSave} />
                </div>
            </div>
        </div>
    );
};

export default ConodeFuturo;
