import AvatarAutonomo from './Avatar-autonomo.svg';
import AvatarFantasioso from './Avatar-fantasioso.svg';
import AvatarGeneralista from './Avatar-generalista.svg';
import AvatarPlaneador from './Avatar-planeador.svg';

const ProfilesPics = {
  AUTÓNOMO: AvatarAutonomo,
  FANTASIOSO: AvatarFantasioso,
  GENERALISTA: AvatarGeneralista,
  PLANEADOR: AvatarPlaneador,
};

export default ProfilesPics;