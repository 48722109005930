import React, { useState, useEffect, useRef } from "react";
import Chip from "@mui/material/Chip";
import './ConoFuturo.css';


const formatKey = (key) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\d+/g, "").trim();
};

const getChipColor = (key, parentKey) => {
    const lowerParentKey = parentKey.toLowerCase();

    if (key.toLowerCase().includes("ambicioso")) return "#EFCE2A";
    if (lowerParentKey.includes("adyacentes")) return "#A4CBF4";
    if (lowerParentKey.includes("altamente")) return "#E592BA";
    if (lowerParentKey.includes("directos")) return "#64CD84";
    return "#ccc";
};

const AnimatedDetails = ({ summaryText, children, chipColor }) => {
    return (
        <div
            className="container-animated"
            style={{
                marginTop: "16px",
                marginBottom: "16px",
                borderRadius: "8px",
                overflow: "visible",
                position: "relative",
                border: `2px solid ${chipColor || "#ccc"}`,
                padding: "16px",
                maxWidth: "70rem",
                minWidth: "17rem",
                zIndex: 0
            }}
        >

            <div
                style={{
                    position: "absolute",
                    top: "-12px",
                    left: "16px",
                    backgroundColor: "#005280",
                    color: "#fff",
                    fontWeight: "400",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    zIndex: 10,
                }}
            >
                {summaryText}
            </div>

            <div
                style={{
                    padding: "8px",
                    backgroundColor: "#005280",
                    borderRadius: "8px",
                    verflowX: "auto",
                    whiteSpace: "nowrap",
                }}
            >
                {children}
            </div>
        </div>
    );
};



const convertJsonToTreeNodes = (json, updateValue, path = [], addChip) => {
    const sectionOrder = ["Puesto", "EscenariosDirectos", "EscenariosAdyacentes", "EscenariosAltamenteDivergentes"];

    const traverse = (obj, path, parentKey = "") => {

        const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) => {
            const indexA = sectionOrder.indexOf(keyA);
            const indexB = sectionOrder.indexOf(keyB);

            if (indexA === -1 && indexB === -1) return 0; 
            if (indexA === -1) return 1; 
            if (indexB === -1) return -1;
            return indexA - indexB; 
        });


        return  sortedEntries.map(([key, value], index, array) => {
            const currentPath = [...path, key];
            const chipColor = getChipColor(key, parentKey);

            if (typeof value === "object" && value !== null) {
                return (
                    <li
                        className="animated-li"
                        key={currentPath.join(".")}
                        style={{
                            listStyleType: "none",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "24px",
                        }}
                    >
                        <AnimatedDetails summaryText={formatKey(key)} chipColor={chipColor}>
                            <ul
                                className="ul-class"
                                style={{
                                    padding: "0",
                                    margin: "16px",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    position: "relative",
                                    maxWidth: "100%",
                                }}
                            >
                                {traverse(value, currentPath, key)}
                                <DropdownButton
                                    chipColor={chipColor}
                                    addChip={(type) => addChip(currentPath, type)}
                                    currentCount={Object.keys(value).length}
                                />
                            </ul>
                        </AnimatedDetails>
                    </li>
                );
            }

            const isPuestoNode = key.toLowerCase().includes("puesto");

            return (
                <li
                    key={currentPath.join(".")}
                    style={{
                        listStyleType: "none",
                        display: !isPuestoNode && "flex",
                        alignItems: "center",
                        position: "relative",
                        marginBottom: "16px",
                        paddingLeft: "20px",
                    }}
                >
                    {index < array.length - 1 && !isPuestoNode && (
                        <div
                            className="lines"
                            style={{
                                position: "absolute",
                                top: "70%",
                                right: "10%",
                                width: "70px",
                                height: "3px",
                                backgroundColor: "#ccc",
                                transform: "translateY(-0%) translateX(100%)",
                                zIndex: 0
                            }}
                        ></div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingRight: "12px",
                            zIndex: 1
                        }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                marginBottom: "8px",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#FFFFFF",
                            }}
                        >
                            {key}
                        </div>
                        <Chip
                            label={
                                <input
                                    type="text"
                                    value={value}
                                    style={{
                                        border: "none",
                                        outline: "none",
                                        background: "transparent",
                                        textAlign: "center",
                                        padding: "10px",
                                        fontSize: "12px",
                                        width: "100%",
                                        maxWidth: "300px",
                                        boxSizing: "border-box",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                    onChange={(e) => updateValue(currentPath, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            updateValue(currentPath, e.target.value);
                                            e.target.blur();
                                        }
                                    }}
                                />
                            }
                            variant="filled"
                            style={{
                                padding: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: chipColor,
                                color: chipColor === "#ccc" ? "#000" : "#fff",
                                fontWeight: "bold",
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                            }}
                        />
                    </div>
                </li>
            );
        });
    };

    return traverse(json, path);
};


const DropdownButton = ({ chipColor, addChip, currentCount }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleButtonClick = (e) => {
        e.preventDefault();
        if (currentCount < 8) setIsOpen((prev) => !prev);
    };

    return (
        <div
         className="plusbutton"
            style={{
                position: "relative",
                display: "inline-block",
            }}
            ref={dropdownRef}
        >
            <button
                
                style={{
                    marginLeft: "12px",
                    padding: "0",
                    width: "32px",
                    height: "32px",
                    cursor: "pointer",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: `0px solid ${chipColor}`,
                    backgroundColor: "#ccc",
                    color: chipColor === "#ccc" ? "#000" : "#fff",
                    transform: "translateY(27px);",
                    fontSize: "18px",
                }}
                onClick={handleButtonClick}
                disabled={currentCount >= 8}
            >
                +
            </button>
            <div
                className="escenario-add"
                style={{
                    position: "absolute",
                    top: "-40px",
                    borderRadius: "8px",
                    zIndex: "9999",
                    opacity: isOpen ? 1 : 0,
                    transform: isOpen ? "translateY(0)" : "translateY(-20px)",
                    transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease",
                    pointerEvents: isOpen ? "auto" : "none",
                    whiteSpace: "nowrap",
                    overflow: "visible",
                    display: "flex",
                    flexDirection: "column",
                    gap: "28px",
                    alignItems: "center",

                }}
            >
                <button

                    onClick={(e) => {
                        e.preventDefault();
                        addChip("escenario");
                        setIsOpen(false);
                    }}
                    style={{
                        marginBottom: "21px",
                        padding: "6px 12px",
                        backgroundColor: "transparent",
                        color: "#C6C7C8",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        width: "100%",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.color = "#8FFFFF";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = "#C6C7C8";
                    }}
                >
                    Agregar Escenario
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        addChip("ambicioso");
                        setIsOpen(false);
                    }}
                    style={{
                        padding: "6px 12px",
                        backgroundColor: "transparent",
                        color: "#C6C7C8",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        width: "100%",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.color = "#EFCE2A";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = "#C6C7C8";
                    }}
                >
                    Agregar Ambicioso
                </button>
            </div>
        </div>
    );
};

const areAllFieldsFilled = (data) => {
    const checkFields = (obj) => {
        return Object.values(obj).every(value => {
            if (typeof value === "object" && value !== null) {
                return checkFields(value);
            } else if (typeof value === "string") {
                return value.trim().length > 0;
            }
            return false;
        });
    };
    return checkFields(data);
};


const ConoFuturo = ({ data, onSave }) => {
    const [localData, setLocalData] = useState(data);
    const updateValue = (path, newValue) => {
        const update = (obj, keys) => {
            if (keys.length === 1) {
                obj[keys[0]] = newValue;
            } else {
                update(obj[keys[0]], keys.slice(1));
            }
        };

        const newData = { ...localData };
        update(newData, path);
        setLocalData(newData);

        if (areAllFieldsFilled(newData)) {
            onSave(newData);
        }
    };


    const addChip = (path, type) => {
        const update = (obj, keys) => {
            if (keys.length === 1) {
                const entries = Object.keys(obj[keys[0]]);
                let newKey = "";
                if (type === "ambicioso") {
                    const ambiciosoKeys = entries.filter((k) =>
                        k.toLowerCase().includes("ambicioso")
                    );
                    newKey = `Ambicioso ${ambiciosoKeys.length + 1}`;
                } else {
                    const regularKeys = entries.filter(
                        (k) => !k.toLowerCase().includes("ambicioso")
                    );
                    newKey = `${regularKeys.length + 1}`;
                }
                obj[keys[0]][newKey] = "";
            } else {
                update(obj[keys[0]], keys.slice(1));
            }
        };
        const newData = { ...localData };
        update(newData, path);
        setLocalData(newData);
    };

    return (
        <div style={{
            maxHeight: "800px",
            overflowY: "auto",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
        }}>
            <ul style={{ padding: "0", display: "flex", flexDirection: "column" }}>
                {convertJsonToTreeNodes(localData, updateValue, [], addChip)}
            </ul>
        </div>
    );
};

export default ConoFuturo;